import React from 'react'

export default function Footer() {
  return (
   <div className="container-fluid">
    <div className="row">
        <div className="col text-center">
            <h1>&copy; All rights reserved</h1>
        </div>
    </div>
   </div>
  )
}
